import React from 'react'
import tw from 'twin.macro'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Layout, Logo, Seo, ArticleImage } from './../../components'

const TextWrap = ({ children }) => (
  <div tw='max-w-3xl px-6 mx-auto'>
    {children}
  </div>
)

const ArticleLayout = ({ data }) => {
  const hero = getImage(data.mdx.frontmatter.hero)
  const authorImage = getImage(data.mdx.frontmatter.author.picture)

  const H1 = props => <TextWrap><h1 {...props} tw='font-bold text-2xl my-2 xl:(text-4xl my-6)' /></TextWrap>
  const H2 = props => <TextWrap><h2 {...props} tw='font-bold text-xl mt-3 mb-1 xl:(text-4xl mt-10 mb-4)' /></TextWrap>
  const H3 = props => <TextWrap><h3 {...props} tw='font-bold text-lg mt-3 mb-1 xl:(text-xl mt-8 mb-3)' /></TextWrap>
  const UL = props => <TextWrap><ul {...props} tw='my-4 list-inside list-disc' /></TextWrap>
  const OL = props => <TextWrap><ol {...props} tw='my-4 list-inside list-decimal' /></TextWrap>
  const LI = props => <li {...props} />
  const DL = props => <TextWrap><dl {...props} tw='my-4 flex' /></TextWrap>
  const DT = props => <dt {...props} tw='w-1/4 font-bold' />
  const DD = props => <dd {...props} tw='flex-1' />
  const P = props => <TextWrap><p {...props} tw='my-3 xl:(text-2xl my-6)' /></TextWrap>
  const A = props => <a {...props} target='_blank' tw='text-blue-600 border-b border-blue-200 dark:(border-blue-900 text-blue-400)' />

  const components = {
    h1: H1,
    h2: H2,
    h3: H3,
    p: P,
    a: A,
    ul: UL,
    ol: OL,
    li: LI,
    dl: DL,
    dt: DT,
    dd: DD,
    ArticleImage: ArticleImage,
  }

  return (
    <Layout>
      <Seo title={data.mdx.frontmatter.title} />
      <Logo/>
      <div tw='flex justify-center items-center relative bg-gray-900'>
        <div tw='h-screen flex flex-col py-24 justify-between items-center z-10 relative px-8 max-w-4xl text-white text-center'>
          <div tw='uppercase text-sm tracking-widest font-medium'>{data.mdx.frontmatter.eyebrow}</div>
          <div>
            <h1 tw='mb-4 text-4xl lg:(text-6xl) font-bold'>{data.mdx.frontmatter.title}</h1>
            <p tw='lg:(text-xl) max-w-xl mx-auto'>{data.mdx.frontmatter.summary}</p>
          </div>
          <div>
            { (authorImage) && <GatsbyImage image={authorImage} alt={data.mdx.frontmatter.author.name} style={{ borderRadius: 9999, overflow: 'hidden' }} imgStyle={{ borderRadius: 9999, overflow: 'hidden' }} /> }
            <p tw='mt-4 text-white text-center'>
              {data.mdx.frontmatter.author.name.substr(0, data.mdx.frontmatter.author.name.indexOf(" "))}
            </p>
          </div>
        </div>
        <GatsbyImage
          image={hero}
          alt={data.mdx.frontmatter.title}
          style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 0, opacity: 0.3}}
        />
      </div>
      <div tw='bg-gray-50 text-gray-800 dark:(background-color[#272a2f] text-gray-50) py-4 md:(py-8) lg:(py-16)'>
        <div className='article' tw='dark:(background-color[#272a2f] text-gray-200) max-w-screen-xl md:(text-lg) lg:(text-xl) xl:(text-2xl) mx-auto'>
          <MDXProvider components={components}>
            <MDXRenderer localImages={data.mdx.frontmatter.localImages}>
              {data.mdx.body}
            </MDXRenderer>
          </MDXProvider>
        </div>
      </div>
      <div tw='bg-gray-900 px-4'>
        <div tw='max-w-screen-xl mx-auto text-white py-16'>
          <h4 tw='text-5xl md:(text-6xl) lg:(text-7xl) text-gray-800 font-bold'>esoterical</h4>
        </div>
      </div>
    </Layout>
  )
}

const VideoLayout = ({ data }) => {
  const hero = getImage(data.mdx.frontmatter.hero)
  const authorImage = getImage(data.mdx.frontmatter.author.picture)

  return (
    <Layout>
      <Seo title={data.mdx.frontmatter.title} />
      <Logo/>
      <div tw='flex flex-wrap items-center relative bg-gray-900 px-8 lg:(px-16 flex-nowrap)'>
        <div tw='flex flex-1 flex-col py-8 justify-between z-10 relative w-full text-white pr-8 order-2 lg:(h-screen order-1 max-w-3xl py-24)'>
          <div tw='uppercase text-sm tracking-widest font-medium'>{data.mdx.frontmatter.eyebrow}</div>
          <div tw='mb-8 lg:(mb-0)'>
            <h1 tw='mb-4 text-4xl font-bold lg:(text-6xl)'>{data.mdx.frontmatter.title}</h1>
            <p tw='lg:(text-xl)'>{data.mdx.frontmatter.summary}</p>
          </div>
          <div>
            { (authorImage) && <GatsbyImage image={authorImage} alt={data.mdx.frontmatter.author.name} style={{ borderRadius: 9999, overflow: 'hidden' }} imgStyle={{ borderRadius: 9999, overflow: 'hidden' }} /> }
            <p tw='mt-4 text-white'>
              {data.mdx.frontmatter.author.name.substr(0, data.mdx.frontmatter.author.name.indexOf(" "))}
            </p>
          </div>
        </div>
        <div tw='w-full z-20 order-1 pt-24 pb-8 lg:(order-2 flex-1 py-0)'>
          <div tw='relative padding-bottom[56.25%] rounded-md overflow-hidden'>
            <iframe
              src={`https://www.youtube-nocookie.com/embed/${data.mdx.frontmatter.link}`}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              tw='absolute inset-0 width[100%] height[100%]'
            />
          </div>
        </div>
        <GatsbyImage
          image={hero}
          alt={data.mdx.frontmatter.title}
          style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 0, opacity: 0.3}}
        />
      </div>
    </Layout>
  )
}

const PageLayout = ({ data }) => {
  if (data.mdx.frontmatter.type === 'video') {
    return <VideoLayout data={data}/>
  }
  return <ArticleLayout data={data}/>
}

export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        eyebrow
        summary
        type
        link
        date(formatString: "MMMM D, YYYY")
        author {
          name
          picture {
            childImageSharp {
              gatsbyImageData(height: 80, width: 80)
            }
          }
        }
        localImages {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
            )
          }
        }
        hero {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      body
    }
  }
`

export default PageLayout
